$(document).ready(initPage);

function initPage() {
  $('.header-toggler').click(function () {
    $('.header-nav').toggleClass('is-collapsed is-m-expanded');
  });

  $('.form-group .form-control').on('change keyup paste input', function () {
    if ($(this).val()) {
      $(this).removeClass('is-empty');
    } else {
      $(this).addClass('is-empty');
    }
  });

  $('.video-popup').magnificPopup({
    type: 'iframe',
    // other options
  });
}
